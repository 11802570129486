<template>
  <div class="card" v-if="isLoaded">
    <div class="card-body">
      <div class="charts-wrapper">
        <div class="doughnutWrapper">
          <h3>{{ $t('kpi.articles_breakdown') }}</h3>
          <doughnut-chart
            v-if="articleTypeChart().labels.length > 0"
            :height="250"
            :chart-data="articleTypeChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
        <div v-if="filter.department > 0" class="doughnutWrapper">
          <h3>{{ $t('kpi.video_types') }}</h3>
          <doughnut-chart
            v-if="videoTypeChart().labels.length > 0"
            :height="250"
            :chart-data="videoTypeChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
        <div v-else class="doughnutWrapper">
          <h3>{{ $t('kpi.viewsStructure') }}</h3>
          <doughnut-chart
            v-if="trafficTypeChart().labels.length > 0"
            :height="250"
            :chart-data="trafficTypeChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
        <div v-if="filter.department > 0" class="doughnutWrapper">
          <h3>{{ $t('kpi.productArticlesComposition') }}</h3>
          <doughnut-chart
            v-if="productsArticlesChart().labels.length > 0"
            :height="250"
            :chart-data="productsArticlesChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
        <div v-else class="doughnutWrapper">
          <h3>{{ $t('kpi.viewsStructureByDepartment') }}</h3>
          <doughnut-chart
            v-if="departmentTypeChart().labels.length > 0"
            :height="250"
            :show-legend="departmentTypeChart().labels.length <= 10"
            :chart-data="departmentTypeChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
        <div v-if="!(filter.department > 0)" class="doughnutWrapper">
          <h3>{{ $t('kpi.ai.articleCountTitle') }}</h3>
          <doughnut-chart
            v-if="articleAiAssistedChart().labels.length > 0"
            :height="250"
            :show-legend="articleAiAssistedChart().labels.length <= 10"
            :chart-data="articleAiAssistedChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
        <div v-if="!(filter.department > 0)" class="doughnutWrapper">
          <h3>{{ $t('kpi.ai.pageViewsCountTitle') }}</h3>
          <doughnut-chart
            v-if="pageViewsAiAssistedChart().labels.length > 0"
            :height="250"
            :show-legend="pageViewsAiAssistedChart().labels.length <= 10"
            :chart-data="pageViewsAiAssistedChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
        <div v-if="!(filter.department > 0)" class="doughnutWrapper">
          <h3>{{ $t('kpi.ai.articleSourceTitle') }}</h3>
          <doughnut-chart
            v-if="articleSourceChart().labels.length > 0"
            :height="250"
            :show-legend="articleSourceChart().labels.length <= 10"
            :chart-data="articleSourceChart()"
          />
          <div v-else>{{ $t('kpi.user_data_not_found') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DoughnutChart from '../../chart/DoughnutChart'
import ArticleTypeMixin from '../../mixins/valueObject/ArticleTypeMixin'
import KpiVideoTypeMixin from '../../mixins/valueObject/KpiVideoTypeMixin'
import KpiArticlesCounter from '../../../model/KpiArticlesCounter'
import KpiVideosCounter from '../../../model/KpiVideosCounter'
import KpiTrafficCounter from '../../../model/KpiTrafficCounter'

export default {
  name: 'KpiArticlesDoughnut',
  mixins: [
    ArticleTypeMixin,
    KpiVideoTypeMixin
  ],
  props: ['isLoaded'],
  components: {
    DoughnutChart
  },
  data () {
    return {
      articleTypeBackgrounds: {
        article: '#55ce63',
        external: '#009efb',
        video: '#ffbc34',
        quiz: '#f62d51',
        photostory: '#2f3d4a',
        test: '#16ce53',
        longform: '#ff9efb',
        recipe: '#ffbc34',
        disease: '#f56d51',
        encyclopedy: '#0f0d4a'
      },
      videoTypeBackgrounds: {
        internal: '#55ce63',
        social: '#009efb',
        agency: '#ffbc34',
        none: '#f62d51',
        native: '#2f3d4a',
        slideshow: '#ff9efb'
      },
      trafficBackgrounds: {
        pageViews: '#55ce63',
        galleryViews: '#009efb'
      },
      articlesAiBackgrounds: {
        withAi: '#FB00A3',
        withoutAi: '#6155CE'
      },
      pageViewsAiBackgrounds: {
        withAi: '#FB1000',
        withoutAi: '#B855CE'
      },
      counterTypes: {
        videoTypesCounter: KpiVideosCounter,
        articleTypesCounter: KpiArticlesCounter,
        trafficTypesCounter: KpiTrafficCounter
      }
    }
  },
  computed: {
    ...mapGetters({
      onlineArticles: 'editorialPerformance/onlineArticles',
      performance: 'editorialPerformance/performanceStatistics',
      kpi: 'editorialPerformance/kpi',
      usersMetrics: 'editorialPerformance/usersMetrics',
      filter: 'editorialPerformance/filter'
    }),
    filteredArticles () {
      const records = this.onlineArticles
      const filteredRecords = []
      if (this.filter.user > 0) {
        records.forEach(record => {
          if (record.sharedUsers.some(user => parseInt(user.userId) === parseInt(this.filter.user))) {
            filteredRecords.push(record)
          }
        })
        return filteredRecords
      }
      return records
    }
  },
  methods: {
    trafficTypeChart () {
      let counterData = this.performance
      if (this.filter.user > 0) {
        counterData = this.usersMetrics.find(obj => {
          return obj.userId === parseInt(this.filter.user)
        })
        if (counterData == null) {
          return this._.cloneDeep(this.counterTypes.trafficTypesCounter)
        }
      }
      const doughnutData = [
        {
          label: this.$t('kpi.articles'),
          value: (counterData.pageViews - counterData.galleryViews).toFixed(2),
          background: this.trafficBackgrounds.pageViews
        },
        {
          label: this.$t('kpi.galleries'),
          value: counterData.galleryViews.toFixed(2),
          background: this.trafficBackgrounds.galleryViews
        }
      ]
      return this.getDoughnutDataStructure(doughnutData)
    },
    videoTypeChart () {
      const doughnutData = []
      const counterData = this.getCounterData('videoTypesCounter')
      this.kpiVideoTypeValues.forEach(type => {
        if (counterData[type.id] > 0) {
          doughnutData.push({
            label: type.title,
            value: counterData[type.id].toFixed(2),
            background: this.videoTypeBackgrounds[type.id]
          })
        }
      })
      this.departmentTypeChart()
      return this.getDoughnutDataStructure(doughnutData)
    },
    productsArticlesChart () {
      const doughnutData = []
      for (const [productId, value] of Object.entries(this.filteredArticlesProductCounter())) {
        const product = this.$store.getters['edonProduct/getById'](productId)
        if (product?.mainProduct) {
          doughnutData.push({
            label: product.title,
            value: value,
            background: this.getRandomColor()
          })
        }
      }
      return this.getDoughnutDataStructure(doughnutData)
    },
    filteredArticlesProductCounter () {
      const counter = []
      this.filteredArticles.forEach(article => {
        article.productsIds.forEach(productId => {
          const product = this.$store.getters['edonProduct/getById'](productId)
          if (product?.mainProduct) {
            if (!Object.prototype.hasOwnProperty.call(counter, productId)) {
              counter[productId] = 0
            }
            counter[productId]++
          }
        })
      })
      return counter
    },
    getRandomColor () {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },
    departmentTypeChart () {
      const doughnutData = []
      const counterData = this.getDepartmentsSummary()

      for (const departmentName in counterData) {
        if (Object.prototype.hasOwnProperty.call(counterData, departmentName)) {
          doughnutData.push({
            label: departmentName,
            value: counterData[departmentName].toFixed(2),
            background: this.getRandomColor()
          })
        }
      }
      return this.getDoughnutDataStructure(doughnutData)
    },
    articleTypeChart () {
      const doughnutData = []
      const counterData = this.getCounterData('articleTypesCounter')
      this.articleTypeValues.forEach(type => {
        if (counterData[type.id] > 0) {
          doughnutData.push({
            label: type.title,
            value: counterData[type.id].toFixed(2),
            background: this.articleTypeBackgrounds[type.id]
          })
        }
      })
      return this.getDoughnutDataStructure(doughnutData)
    },
    articleAiAssistedChart () {
      const counterData = this.performance
      const doughnutData = [
        {
          label: this.$t('kpi.ai.withAi'),
          value: counterData.articlesAiAssisted.toFixed(2),
          background: this.articlesAiBackgrounds.withAi
        },
        {
          label: this.$t('kpi.ai.withoutAi'),
          value: (counterData.articles - counterData.articlesAiAssisted).toFixed(2),
          background: this.articlesAiBackgrounds.withoutAi
        }
      ]
      return this.getDoughnutDataStructure(doughnutData)
    },
    pageViewsAiAssistedChart () {
      const counterData = this.performance
      const doughnutData = [
        {
          label: this.$t('kpi.ai.withAi'),
          value: counterData.pageViewsAiAssisted.toFixed(2),
          background: this.pageViewsAiBackgrounds.withAi
        },
        {
          label: this.$t('kpi.ai.withoutAi'),
          value: (counterData.pageViews - counterData.pageViewsAiAssisted).toFixed(2),
          background: this.pageViewsAiBackgrounds.withoutAi
        }
      ]
      return this.getDoughnutDataStructure(doughnutData)
    },
    articleSourceChart () {
      const counterData = this.performance
      const doughnutData = [
        'articleSourceAuthor',
        'articleSourceAgency',
        'articleSourceFromPrint',
        'articleSourceTransferred',
        'articleSourceAuthorAi',
        'articleSourceAgencyAi',
        'articleSourceFromPrintAi',
        'articleSourceTransferredAi'
      ].map(key => ({
        label: this.$t(`kpi.ai.${key}`),
        value: key.includes('Ai') ? counterData[key].toFixed(2) : (counterData[key] - counterData[`${key}Ai`]).toFixed(2),
        background: this.getRandomColor()
      }))
      return this.getDoughnutDataStructure(doughnutData)
    },
    getCounterData (counterType) {
      if (this.filter.user > 0) {
        const user = this.usersMetrics.find(obj => {
          return obj.userId === parseInt(this.filter.user)
        })
        if (user) {
          return user[counterType]
        }
        return this._.cloneDeep(this.counterTypes[counterType])
      }
      return this.performance[counterType]
    },
    getDepartmentsSummary () {
      const departmentsCounts = []
      this.usersMetrics.forEach(user => {
        departmentsCounts[user.departmentName] = (departmentsCounts[user.departmentName] || 0) + user.pageViews
      })
      return departmentsCounts
    },
    getDoughnutDataStructure (doughnutData) {
      const dataSets = this.sortLabels(doughnutData)
      return {
        labels: dataSets.labels,
        datasets: [
          {
            data: dataSets.values,
            backgroundColor: dataSets.backgrounds
          }
        ]
      }
    },
    sortLabels (doughnutData) {
      const dataSets = {
        labels: [],
        values: [],
        backgrounds: []
      }
      doughnutData.sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
      for (let i = 0; i < doughnutData.length; i++) {
        dataSets.labels.push(doughnutData[i].label)
        dataSets.values.push(doughnutData[i].value)
        dataSets.backgrounds.push(doughnutData[i].background)
      }
      return dataSets
    }
  }
}
</script>

<style lang="scss" scoped>
.charts-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  .doughnutWrapper {
    padding: 0.5rem;
    max-width: rem(450px);
    min-width: rem(450px);
    h3 {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}

.doughnutWrapper h3 {
  margin: 0;
  padding: 0;
}
</style>
