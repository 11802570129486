<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterAll">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-select
                v-if="filterType === 'product'"
                v-model="filter.product"
                :options="edonProducts"
                optionTitle="title"
                :convertValue="false"
                id="performanceFilter_product"
                :label="$t('rubric.product')"
                @change="unload"
              >
              </app-select>
              <app-select
                v-if="filterType === 'department'"
                v-model="filter.department"
                :options="departments"
                optionTitle="name"
                :convertValue="false"
                id="performanceFilter_department"
                :label="$t('menu.departments')"
                @change="unload"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6" v-if="filter.department > 0 && filterType === 'department'">
              <app-select
                v-model="filter.user"
                :options="departmentUsers"
                optionTitle="fullName"
                id="performanceFilter_user"
                :label="$t('kpi.user')"
              >
              </app-select>
            </div>
            <div v-if="filter.timeGroupType === 'week'" class="col-lg-2 col-md-8">
              <app-date-picker
                v-model="filter.seedDate"
                :label="$t('kpi.week')"
                :weekNumbers="true"
                id="week_seed_date"
                type="week"
                :tool-tip-text="$t('kpi.toolTip.weekSelect')"
                @change="unload"
              ></app-date-picker>
            </div>
            <div v-else-if="filter.timeGroupType === 'month'" class="col-lg-2 col-md-8">
              <app-date-picker
                v-model="filter.seedDate"
                :label="$t('kpi.month')"
                id="month_seed_date"
                customDateFormat="Z"
                type="month"
                @change="unload"
              ></app-date-picker>
            </div>
            <div class="col-lg-1 m-t-25">
              <app-checkbox
                v-if="filter.timeGroupType === 'month'"
                v-model="filter.gcpData"
                id="gcpData"
                :label="$t('kpi.gcpData')"
              >
              </app-checkbox>
            </div>
            <div class="col-lg-1">
              <button
                v-if="filter.seedDate && (filter.product || filter.department)"
                class="btn btn-success pull-right m-t-20"
                @click.prevent="filterAll()"
              >{{ $t('beUser.filter.search') }}
              </button>
            </div>
          </div>
          <div v-if="filter.product === 'all'">
            <div class="alert alert-warning">
              <i class="fa fa-exclamation-triangle"></i> {{ $t('kpi.longLoadingWarning') }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Select from '../form/select/Select'
import Checkbox from '../form/Checkbox'
import ProductPerformanceFilter from '../../model/ProductPerformanceFilter'
import WeightsSettingsFilter from '../../model/WeightsSettingsFilter'
import KpiSettingsFilter from '../../model/KpiSettingsFilter'
import DatePicker from '../form/Datepicker'
import moment from 'moment'
import { DISABLED_DEPARTMENTS } from '../../model/ValueObject/DisabledDepartments'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductPerformanceFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(ProductPerformanceFilter),
      weightsFilter: this._.cloneDeep(WeightsSettingsFilter),
      kpiFilter: this._.cloneDeep(KpiSettingsFilter),
      weeklyCalendar: [],
      filterOutDepartments: DISABLED_DEPARTMENTS
    }
  },
  components: {
    appSelect: Select,
    appCheckbox: Checkbox,
    appDatePicker: DatePicker
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapGetters('weights', ['weights']),
    company () {
      return this.$store.getters.company.toUpperCase()
    },
    edonProducts () {
      const products = this.$store.getters['edonProduct/list'].map(product => ({ ...product, lvl: 1 }))
      const all = {
        id: 'all',
        title: this.$t('kpi.allProducts', { company: this.company }),
        lvl: 0
      }
      products.unshift(all)
      return products
    },
    departments () {
      return this.$store.getters['department/all'].filter(function (department) {
        return this.filterOutDepartments.indexOf(department.id) < 0
      }, this)
    },
    departmentUsers () {
      const dept = this.$store.getters['department/getById'](this.filter.department)
      const users = dept.users.map(userID => {
        return this.$store.getters['user/userById'](userID)
      })
      return users.sort(function (a, b) {
        return a.fullName.localeCompare(b.fullName)
      })
    },
    timeGroupTypes () {
      return [
        {
          id: 'month',
          title: this.$t('kpi.monthly')
        }
      ]
    }
  },
  props: ['filterType'],
  watch: {
    'filter.department': function () {
      this.filter.user = 0
    }
  },
  methods: {
    getEdonProducts () {
      this.$store.dispatch('edonProduct/fetchAll')
        .then(() => {
          this.isLoading = true
        })
    },
    getDepartments () {
      this.$store.dispatch('department/fetchAll')
        .then(() => {
          this.isLoading = true
        })
    },
    filterAll () {
      this.$store.commit('editorialPerformance/setFilter', this.filter)
      this.weightsFilter.monthYear = moment(this.filter.seedDate).format('M-YYYY')
      this.kpiFilter.monthYear = moment(this.filter.seedDate).format('M-YYYY')
      if (this.filterType === 'product') {
        this.$store.dispatch('editorialPerformance/fetchProductView')
        this.weightsFilter.product = this.filter.product
        this.weightsFilter.department = 0
        this.$store.commit('weights/setFilter', this.weightsFilter)
        this.$store.dispatch('weights/fetch')
      }
      if (this.filterType === 'department') {
        this.$store.dispatch('editorialPerformance/fetchDepartmentView')
        this.weightsFilter.product = 0
        this.weightsFilter.department = this.filter.department
        this.$store.commit('weights/setFilter', this.weightsFilter)
        this.$store.dispatch('weights/fetch')
        this.kpiFilter.department = this.filter.department
        this.$store.commit('kpi/setFilter', this.kpiFilter)
        this.$store.dispatch('kpi/fetch')
      }
    },
    unload () {
      this.$store.commit('editorialPerformance/setLoadedProduct', false)
      this.$store.commit('editorialPerformance/setLoadedDepartment', false)
    }
  },
  beforeMount () {
    this.$store.commit('editorialPerformance/setFilter', this._.cloneDeep(ProductPerformanceFilter))
    this.$store.commit('weights/setFilter', this._.cloneDeep(WeightsSettingsFilter))
    this.$store.commit('kpi/setFilter', this._.cloneDeep(KpiSettingsFilter))
    this.filter = this.$store.getters['editorialPerformance/filter']
    this.weightsFilter = this.$store.getters['weights/filter']
    this.kpiFilter = this.$store.getters['kpi/filter']
  },
  mounted () {
    this.getEdonProducts()
    this.getDepartments()
  }
}
</script>

<style lang="scss">
.hidden {
  display: none;
}
</style>
