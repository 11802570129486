<template>
  <div class="card" v-if="isLoaded">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-2 col-md-6">
          <h3>{{ $t('kpi.print_articles') }}</h3>
        </div>
        <div class="col-lg-10 col-md-6">
          <app-number-of-records-selector
            :activeNumber="perPage"
            @number-of-records="setRecordsNumber"
          >
          </app-number-of-records-selector>
        </div>
      </div>
      <app-data-table
        :data="renderListPrint(printCurrentPage)"
        :pagination="true"
        :total-count="filteredPrintArticles.length"
        :limit="perPage"
        :page="printCurrentPage"
        :config="printArticlesDtConfig"
        action-id-param="documentId"
        @page-change="renderListPrint"
        :data-sorted-by="sortedBy"
        :data-sorted-order="sortedOrder"
        @data-sort-by="sortBy"
      >
      </app-data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '../../shared/DataTable'
import NumberOfRecordsSelector from './NumberOfRecordsSelector'
import { mapState } from 'vuex'

const DEFAULT_SORT_BY = 'title'
const DEFAULT_SORT_ORDER = -1
const DEFAULT_PER_PAGE_RECORDS = 5

export default {
  name: 'PrintArticlesList',
  data () {
    return {
      printPageToOpen: 1,
      printCurrentPage: 1,
      printArticlesDtConfig: {
        fields: {
          title: this.$t('printArticle.title'),
          orderDate: this.$t('printArticle.orderDate'),
          sharedUsers: this.$t('printArticle.sharedUsers'),
          type: this.$t('printArticle.type'),
          quality: this.$t('printArticle.quality')
        },
        render: {
          sharedUsers: (sharedUsers) => {
            return sharedUsers.map(sharedUser => {
              return sharedUser.userName
            }).join(', ')
          }
        },
        toolTip: {
          type: this.$t('kpi.toolTip.printType'),
          quality: this.$t('kpi.toolTip.printQuality')
        }
      },
      sortedBy: DEFAULT_SORT_BY,
      sortedOrder: DEFAULT_SORT_ORDER,
      perPage: DEFAULT_PER_PAGE_RECORDS
    }
  },
  methods: {
    setRecordsNumber (number) {
      this.printCurrentPage = 1
      this.perPage = number
    },
    renderListPrint (pageNumber = 1) {
      const recordsToDisplay = []
      if (this.filteredPrintArticles && this.filteredPrintArticles.length) {
        this.printPageToOpen = pageNumber
        for (let i = this.startPrint; i <= this.stopPrint; i++) {
          recordsToDisplay.push(this.filteredPrintArticles[i])
        }
        this.printCurrentPage = this.printPageToOpen
      }
      return recordsToDisplay
    },
    sortBy (field) {
      if (field === this.sortedBy) {
        this.sortedOrder = -1 * this.sortedOrder
      }
      this.sortedBy = field
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    filteredPrintArticles () {
      const records = this.printArticles
      const filteredRecords = []
      if (this.filter.user > 0) {
        records.forEach(record => {
          if (record.sharedUsers.some(user => parseInt(user.userId) === parseInt(this.filter.user))) {
            filteredRecords.push(record)
          }
        })
        return filteredRecords
      }
      return records
    },
    startPrint () {
      return (this.printPageToOpen - 1) * this.perPage
    },
    stopPrint () {
      if ((this.filteredPrintArticles.length - this.startPrint) >= this.perPage) {
        return (this.printPageToOpen * this.perPage) - 1
      } else {
        return this.filteredPrintArticles.length - 1
      }
    },
    filter () {
      return this.$store.getters['editorialPerformance/filter']
    },
    /* eslint-disable */
    printArticles () {
      return this.$store.getters['editorialPerformance/printArticles'].sort((a, b) => {
        if (a[this.sortedBy] < b[this.sortedBy]) {
          return -1 * this.sortedOrder
        }
        if (a[this.sortedBy] > b[this.sortedBy]) {
          return this.sortedOrder
        }
        return 0
      })
    }
  },
  props: ['isLoaded'],
  components: {
    appDataTable: DataTable,
    appNumberOfRecordsSelector: NumberOfRecordsSelector
  }
}
</script>

<style scoped>

</style>
